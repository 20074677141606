body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

a {
    cursor: pointer;
}

.navbar .profile-picture {
	width: 32px;
    height: 32px;
    margin-right: 10px;
}

.navbar .logout {
    color: #777;
    margin-left: 20px;
}

.license-list-table {
    margin-bottom: 20px;
}